.privacy-policy-page {
  text-align: center;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #f8f5f1;
  background: linear-gradient(180deg, #1a1e5d, #3b3086, #482e82);
  box-sizing: border-box;
}

.content-with-policy {
  max-width: 700px;
  text-align: left;
  background: rgba(0, 0, 0, 0.15);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: glow 5s infinite ease-in-out;
}

.policy-section {
  margin-bottom: 30px;
}

.policy-icon {
  color: #fbd25d;
  margin-right: 10px;
  font-size: 1.5em;
}

h1, h2 {
  font-family: 'WonderYarnFont', cursive;
  color: #e2d4ff;
}

p, ul {
  font-size: 1.2em;
  line-height: 1.8;
}
/*
  color: #f8f5f1;
*/
ul {
  margin-left: 20px;
}

.last-updated {
  font-size: 1em;
  color: #f8f5f1;
  margin-top: 20px;
}

/* Magical glowing background */
@keyframes glow {
  0% { background: rgba(0, 0, 0, 0.2); }
  50% { background: rgba(0, 0, 0, 0.3); }
  100% { background: rgba(0, 0, 0, 0.2); }
}

/* Mobile responsive */
@media (max-width: 768px) {
  .content-with-policy {
    padding: 20px;
    text-align: center;
  }
}
/* Align icon and text */
.icon-text {
  display: flex;
  align-items: center;
}

