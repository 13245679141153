/* Ensure full height is taken up */
body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.landing-page {
  flex: 1; /* Grow to push footer to the bottom */
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.footer-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #fbd25d;
  color: #1a1e5d;
  padding: 12px;
  border-radius: 50px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  z-index: 100; /* Ensure it's above other elements */
}

.footer-button:hover {
  background-color: #e2c048; /* Darken the yellow on hover */
}

.footer {
  display: none; /* Initially hidden */
}

.footer-visible {
  display: block;
  bottom: 0;
  position: fixed;
  left: 0;
  width: 100%;
  background-color: #3b3086; /* Dark purple background */
  color: #f8f5f1;
  padding: 20px;
  text-align: center;
  font-size: 0.9em;
  z-index: 99; /* Ensure the footer is above other content */
  transition: bottom 0.3s ease-in-out; /* Smooth transition */
}

.footer-hidden {
  transform: translateY(100%); /* Hide footer when not visible */
}

.footer-visible {
  transform: translateY(0); /* Show footer when visible */
}
.footer-nav {
  display: flex;
  justify-content: center; /* Center the links horizontally */
  gap: 20px; /* Space between links */
  flex-wrap: wrap; /* Ensure it wraps on smaller screens */
}

.footer-link {
  font-size: 1em; /* Slightly smaller for better layout */
  margin: 0 15px; /* Ensure even spacing around the links */
  text-decoration: none;
}

/* Hover effect on links */
.footer-link:hover {
  color: #e2c048; /* Darken gold on hover */
}

