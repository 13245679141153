.contact-us-page {
  text-align: center;
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  overflow: hidden; /* Ensure nothing overflows */
  color: #f8f5f1;
  background: linear-gradient(180deg, #1a1e5d, #3b3086, #482e82); /* Same magical gradient */
  min-height: 100vh;
  box-sizing: border-box; /* Include padding in the height calculation */
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.contact-email:hover {
  text-decoration: underline;
  color: #e2c048; /* Make it more interactive */
}

.content {
  max-width: 600px;
  text-align: left;
  background: rgba(0, 0, 0, 0.15);
  padding: 30px;
  border-radius: 15px;
  animation: glow 5s infinite ease-in-out;
}

.mascot-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mascot {
  margin-top: 20px;
  max-width: 250px; /* Adjusted size for mascot */
  animation: float 3s ease-in-out infinite;
}

@keyframes glow {
  0% {
    background: rgba(0, 0, 0, 0.2);
  }
  50% {
    background: rgba(0, 0, 0, 0.3);
  }
  100% {
    background: rgba(0, 0, 0, 0.2);
  }
}

h1 {
  font-size: 2.5em;
  color: #e2d4ff; /* Lavender */
  font-family: 'Pacifico', cursive; /* Whimsical font */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

a {
  color: #fbd25d;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  color: #e2c048;
}

.socials ul {
  list-style: none;
  padding: 0;
}

.socials li {
  font-size: 1.2em;
  margin: 10px 0;
  color: #fbd25d; /* Keep it in sync with the color scheme */
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Adjust the stars height to always match the viewport */
  background: url('../assets/stars.png') repeat;
  background-size: cover;
  animation: twinkle 15s infinite linear;
  pointer-events: none;
  z-index: -1;
}
.social-icons {
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.social-icons a {
  position: relative;
  text-decoration: none;
  color: inherit;
}


.social-icons a::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.6), 0 0 20px rgba(255, 255, 255, 0.4);
  transition: box-shadow 0.3s ease-in-out;
}

.social-icons a:hover::after {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.9), 0 0 25px rgba(255, 255, 255, 0.7);
}


/* Instagram */
.social-icons .instagram {
  color: #E4405F; /* Instagram's pinkish red */
}

/* Twitter */
.social-icons .twitter {
  color: #1DA1F2; /* Twitter's blue */
}

/* Facebook */
.social-icons .facebook {
  color: #1877F2; /* Facebook's blue */
}

/* TikTok */
.social-icons .tiktok {
  color: #000000; /* TikTok's black */
}

/* Add hover effect for all icons */
.social-icons a:hover {
  transform: scale(1.1); /* Slightly enlarges on hover */
  transition: transform 0.2s ease-in-out;
}

/* Style for each social media icon with a background */
.social-icon {
  background-color: rgba(255, 255, 255, 0.8); /* Solid background color with a bit of opacity */
  padding: 10px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Soft shadow for depth */
}


/* Hover effect for social media icons */
.social-icon:hover {
	  transform: scale(1.1); /* Hover effect */
}

.social-icon svg {
  width: 50px;
  height: 50px;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .content {
    padding: 20px; /* Reduce padding for smaller screens */
    text-align: center; /* Center-align the text on mobile */
  }

  .social-icons {
    gap: 15px; /* Reduce the gap between social icons */
    margin-top: 20px; /* Adjust the top margin to space out content */
  }

  .social-icon svg {
    width: 40px; /* Make the icons slightly smaller on mobile */
    height: 40px;
  }

  h1 {
    font-size: 2em; /* Reduce font size for the heading on mobile */
  }

  p {
    font-size: 1.1em; /* Adjust paragraph text size for mobile */
  }

  .mascot {
    max-width: 180px; /* Scale down the mascot slightly */
    margin-top: 15px;
  }
}

