/* General Navbar Styles */
.navbar {
  width: 100%;
  height: 70px;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
/*  justify-content: space-between; */
  background: rgba(59, 48, 134, 0.8);
  z-index: 100; /* Keep navbar on top */
  justify-content: space-between !important;
  box-sizing: border-box; /* Include padding in the height calculation */
}

.logo-container {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Spacing between logo and nav-links */
}


/* Ensure the logo scales properly inside the navbar without increasing its height */
.logo-container img {
  max-height: 100%; /* Make the logo fit the navbar height */
  height: 77px; /* Control the size of the logo, adjust to fit your design */
  width: auto; /* Maintain aspect ratio */
}


.navbar-logo {
  height: 77px; /* Adjust the height as needed */
  margin-right: 20px; /* Add spacing between the logo and nav links */
}

.hamburger {
  display: none; /* Hidden by default (shown only on mobile) */
}

.hamburger svg {
  width: 24px;
  height: 24px;
  color: #3b3086; /* Purple icon color */
}

.hamburger-menu {
  display: none; /* Hidden by default */
}

.navbar nav {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-right: 100px;
}

.nav-link {
  color: #f8f5f1;
  text-decoration: none;
  font-size: 1.1em;
  transition: color 0.3s, transform 0.3s;
}

.nav-link:hover {
  color: #fbd25d;
  transform: scale(1.05); /* Slightly enlarge the link */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow for visibility */
}

/* Mobile Menu Specific Styles */
@media (max-width: 768px) {
  .navbar {
    display: none; /* Hide the desktop navbar on mobile */
  }
  .logo-container img {
    height: 40px; /* Scale down the logo for mobile */
  }
  .hamburger {
    display: flex; /* Show the hamburger icon */
    position: fixed;
    top: 10px;
    left: 20px;
    z-index: 150;
    background-color: #fbd25d;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }

  .hamburger-menu {
    display: none;
  }

  .menu-open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(59, 48, 134, 0.95); /* Semi-transparent purple */
    position: fixed;
    top: 0;
    left: 0;
    width: 80%; /* Occupy less space on the screen */
    height: 100%;
    z-index: 150; /* Ensure it stays above other content */
  }

  .menu-open .nav-link {
    padding: 20px;
    text-align: center;
    font-size: 1.5em;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  .mobile-language-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ffffff4d;
    color: #fff;
    font-size: 1.5em;
    padding: 20px;
    text-align: center;
  }

  .mobile-language-options {
	      background-color: #3b3086; /* Same as the menu background */
  }

  .mobile-language-options button {
    background-color: transparent;
    color: #fff;
    padding: 10px 20px;
    width: 100%;
    text-align: left;
    font-size: 1em;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mobile-language-options button:hover {
	  background-color: rgba(255, 255, 255, 0.1);
  }

  .flag-icon {
    width: 20px;
    height: auto;
    border-radius: 10px;
  }
    .mobile-language-btn {
    background-color: transparent;
    color: #ffffff;
    font-size: 1.2em;
    padding: 15px 20px;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .current-flag-icon {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }

  .mobile-language-btn svg {
    margin-left: 10px;
  }
}

/* Desktop-specific styles */
@media (min-width: 769px) {
  .hamburger {
    display: none; /* Hide the hamburger on desktop */
  }

  .nav-links {
    display: flex;
    gap: 10px;
  }

  .nav-link {
    padding: 0 10px;
    font-size: 1.1em;
  }
}

/* Dropdown Button */
.dropdown {
  position: relative;
  display: inline-block;
}


/* Dropdown Button */
.dropdown-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #ffffff;
  color: #3b3086;
  padding: 10px 12px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.dropdown-btn:hover,
.dropdown-btn:focus,
.dropdown.open .dropdown-btn {
  background-color: #fbd25d;
  color: #fbd25d;
}

  /*color: #3b3086;*/
.current-flag-icon {
  width: 20px;
  height: auto;
}

.dropdown-content button {
  color: #3b3086;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/*
.dropdown-content button {
  background-color: transparent;
  color: #f8f5f1;
  padding: 12px 16px;
  width: 100%;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}
*/
.dropdown-content button:hover {
  background-color: #f1c232;
}

/* Style for the Chevron icon */
.dropdown-btn svg {
  width: 16px;
  height: 16px;
  color: #3b3086;
}

/* Close dropdown when clicking elsewhere */
.dropdown-content {
  display: none;
  background-color:#fff;
  margin-top:29px;
}
.dropdown-item a {
	margin-right: 10px;
}
.dropdown:hover .dropdown-content {
  display: block;
}

/* Style for the Flag Icons */
.flag-icon {
  width: 20px;
  height: auto;
  margin-right:8px;
}

/* User Dropdown */
.user-dropdown {
  position: relative;
  display: inline-block;
}

.user-dropdown-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fbd25d;
  color: #3b3086;
  padding: 10px 12px;
  font-size: 1em;
  border: none;
  border-radius: 50px; /* Rounded edges */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-dropdown-btn:hover {
  background-color: #f8f5f1;
}

.user-dropdown-content {
  position: absolute;
  right: 0;
  top:  calc(100% + 5px);
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  min-width: 200px;
  z-index: 200;
}

.dropdown-item:first-child {
  border-top-left-radius: 12px; /* Rounded corners for the top item */
  border-top-right-radius: 12px;
}

.dropdown-item:last-child {
  border-bottom-left-radius: 12px; /* Rounded corners for the bottom item */
  border-bottom-right-radius: 12px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 10px 16px;
  font-size: 1em;
  color: #3b3086;
  text-decoration: none;
  background-color: transparent;
  transition: background-color 0.3s ease, transform 0.2s;
}

.dropdown-item:hover {
  background-color: #fbd25d;
  color: #ffffff;
  transform: translateX(5px); /* Fun animation */
  margin-right: 15px;
}

.user-dropdown-content::before {
  content: '';
  position: absolute;
  top: -10px;
  right: 20px;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  transform: rotate(45deg); /* Creates the small arrow */
  z-index: -1; /* Places it behind the menu */
}

/* User Icon Styling */
.user-icon {
  font-size: 1.5em;
  color: #ffffff; /* Default state - white */
  margin-right: 8px;
  transition: color 0.3s ease;
}

/* When hovering over the user dropdown button */
.user-dropdown:hover .user-icon {
  color: #fbd25d; /* Yellow when dropdown is hovered */
}

/* When hovering over dropdown items, revert icon to white */
.user-dropdown:hover .dropdown-item:hover ~ .user-dropdown-btn .user-icon,
.dropdown-item:hover .user-icon {
  color: #ffffff;
}
