/* Basic reset */
body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Helvetica Neue', sans-serif;
  background: linear-gradient(180deg, #1a1e5d, #3b3086, #482e82); /* Deep twilight blues */
  color: #f8f5f1; /* Soft whites/pastels */
}

.landing-page {
  text-align: center;
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.header .logo {
  max-width: 200px; /* Reduce the size of the logo */
  margin-bottom: 30px;
}

/* Add a container for content and mascot */
.content-with-mascot {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px; /* Adds space between the text and mascot */
  margin-bottom: 20px; /* Ensure there is space between the content and footer */
  padding-top: 90px;
}

.content {
  max-width: 500px;
  text-align: left;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.15); /* Background for text readability */
  padding: 20px;
  border-radius: 10px;
  animation: glow 5s infinite ease-in-out;
  margin-bottom: 20px;
}

.mascot-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes glow {
  0% {
    background: rgba(0, 0, 0, 0.2);
  }
  50% {
    background: rgba(0, 0, 0, 0.3);
  }
  100% {
    background: rgba(0, 0, 0, 0.2);
  }
}

.content h1 {
  font-size: 2.5em;
  color: #e2d4ff; /* Lavender */
  margin-bottom: 20px;
  font-family: 'Pacifico', cursive; /* Adds a whimsical touch to the title */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow for contrast */
}

.content p {
  font-size: 1.2em;
  color: #f8f5f1; /* Soft white */
  max-width: 600px;
  line-height: 1.6;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow for contrast */
}

.cta-button {
  background-color: #fbd25d;
  color: #1a1e5d;
  font-size: 1.2em;
  border: none;
  padding: 15px 30px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
  margin: 20px auto; /* Centers the button properly */
  display: block; /* Prevents button from taking full width */
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(251, 210, 93, 0.5);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 25px rgba(251, 210, 93, 0.75);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(251, 210, 93, 0.5);
  }
}

.cta-button:hover {
  background-color: #e2c048; /* Darken the yellow slightly on hover */
}

.cta-subtext {
  font-size: 0.9em;
  color: #f8f5f1;
  margin-top: 10px;
}

.mascot-section {
  margin-top: 20px;
    max-width: 350px;
  animation: float 3s ease-in-out infinite, scale 6s ease-in-out infinite;
}

.mascot {
  margin-top: 20px;
  max-width: 200px; /* Slightly reduce mascot size */
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../assets/stars.png') repeat;
  background-size: cover;
  animation: twinkle 15s infinite linear;
  pointer-events: none;
  z-index: -1; /* Ensure stars are behind content */
}

@keyframes twinkle {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 10px 10px;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Adds a slight scaling effect */
  }
}

.footer {
  text-align: center;
  padding: 20px 10px;
  background-color: #3b3086;
  color: #f8f5f1;
  font-size: 0.9em;
  width: 100%;
  position: relative;
  margin-top: auto; /* Pushes footer to the bottom when content is short */
}


/* Ensure footer is responsive on mobile */
@media (max-width: 768px) {
  .content {
    padding: 10px; /* Adjust padding for mobile */
  }
  .footer {
    font-size: 0.8em; /* Slightly smaller text */
  }
  .content-with-mascot {
    flex-direction: column; /* Stack content and mascot vertically */
    margin-top: 75px;
  }

  .cta-button {
    width: 80%; /* Adjust button size for mobile */
    margin-top: 20px; /* Ensure space between button and mascot */
  }
  .mascot {
    max-width: 200px; /* Reduce mascot size on mobile */
  }

  .navbar {
    padding: 10px 20px; /* Add some extra padding for mobile */
  }

  .nav-link {
    font-size: 1em; /* Slightly reduce the size of the nav links */
  }
  .landing-page {
    padding-top: 120px; /* Ensure content is not behind the navbar */
  }
}

/* Fixing the navbar for mobile and other viewports */
.navbar {
  width: 100%;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  background: rgba(59, 48, 134, 0.8); /* Transparency for a dreamy look */
  z-index: 100; /* Ensure navbar stays on top */
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 2em;
  cursor: pointer;
}
/* Styling for the interests dropdown */
.interests-dropdown {
  background-color: #f8f5f1; /* Soft white background */
  color: #3b3086; /* Purple text color */
  border: 1px solid #ddd;
  font-size: 1em;
}

.interests-dropdown__menu {
  background-color: #f8f5f1; /* Ensure the dropdown menu has a readable background */
  color: #3b3086; /* Ensure text is visible */
}

.interests-dropdown__menu-list {
  background-color: #f8f5f1; /* Make sure each list item has the correct background */
}

.interests-dropdown__option--is-selected {
  background-color: #e2c048; /* Highlight selected options */
  color: #3b3086;
}

.interests-dropdown__option--is-focused {
  background-color: #fbd25d; /* Add a hover effect for readability */
  color: #3b3086;
}

.interests-dropdown__control {
  border-color: #ddd; /* Match control border color */
  box-shadow: none; /* Remove default focus outline */
}

