.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: linear-gradient(180deg, #2a2f70, #4536a5, #5a3eb6);
  padding: 50px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4),
              inset 0 0 100px rgba(255, 255, 255, 0.1);
  position: relative;
  max-width: 400px;
  width: 90%;
  text-align: center;
  color: #f8f5f1;
}

.close-button-solid {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #673ab7;
  border: 2px solid rgba(255, 255, 255, 0.5);   /* More visible border */
  font-size: 1.2em;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 50%;
  transition: all 0.3s ease;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2); /* Added glow */
}

.close-button-solid:hover {
  background-color: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.8);
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}


h1 {
  font-size: 2em;
  margin-bottom: 1.5em;
  color: #fff;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

input {
  width: 100%;
  padding: 12px;
  margin: 15px 0;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 1em;
  background: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #b388ff;
  box-shadow: 0 0 15px rgba(179, 136, 255, 0.3);
  transform: translateY(-2px);
}

button {
  width: 100%;
  padding: 14px;
  margin-top: 20px;
  border-radius: 8px;
  border: none;
  background-color: #7c4dff;
  color: #fff;
  font-size: 1.2em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

button:hover {
  background-color: #6c3aef;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
}

.google-button {
  background-color: #db4437;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  transition: none;
  color: white;
}

.google-button:hover {
  background-color: #db4437;
  transform: none;
  box-shadow: none;
}

.google-button svg {
  flex-shrink: 0;
}

.error-message {
  color: #ff8a80;
  margin-top: 10px;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.2);
}

.mascot-login {
  position: absolute; 
  top: 50%; 
  right: -60px; 
  transform: translateY(-50%); 
  max-width: 120px;
  animation: float 3s ease-in-out infinite;
}
/*@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}
*/


.close-button-solid {
  position: absolute;
  top: 15px;
  right: 15px;
  border: 2px solid rgba(255, 255, 255, 0.5);   /* More visible border */
  font-size: 1.2em;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 50%;
  transition: all 0.3s ease;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2); /* Added glow */
}

.close-button-solid:hover {
  background-color: #f5e6ff;
  border-color: rgba(255, 255, 255, 0.8);
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

/* ... (other styles remain the same until additional-links) ... */

.additional-links {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 0 10px;
}

.additional-links a {
  color: #f8f5f1;
  font-size: 1.2em;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  text-shadow: 2px 2px 4px #00000080;
  position: relative;
  padding: 4px 8px;
  border-radius: 4px;
}

.additional-links a:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  text-shadow: 0 0 12px rgba(255, 255, 255, 0.5);
  transform: translateY(-1px);
}

.additional-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  transition: width 0.3s ease;
}

.additional-links a:hover::after {
  width: 100%;
}
/* Add these styles to your existing LoginModal.css */

.checkbox-wrapper {
  margin: 20px 0;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox-group input[type="checkbox"] {
  margin: 0; /* Remove default margins */
  flex-shrink: 0; /* Prevent the checkbox from shrinking */
  width: 16px; /* Set a consistent size */
  height: 16px;
}


.checkbox-group label a {
  color: #f8f5f1; /* Keep the link color distinct */
  text-decoration: underline;
  margin: 0; /* Remove additional margin that might cause misalignment */
}

.checkbox-group label a:hover {
  color: #ffffff;
}
.checkbox-group label {
  line-height: 1.4; /* Adjust for better alignment */
  color: #f8f5f1; /* Ensure readability */
  font-size: 0.9em; /* Optional: Adjust the text size */
}

.checkbox-group a {
  margin: 0 4px;
  padding: 0;
  color: #f8f5f1;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.checkbox-group a:hover {
  color: #ffffff;
}

.success-message {
  color: #4caf50;
  margin-top: 10px;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(76, 175, 80, 0.2);
}

/* Adjust modal content max-height for registration */
.modal-content {
  position: relative; /* Ensures child absolute elements are positioned relative to this */
  background: linear-gradient(180deg, #2a2f70, #4536a5, #5a3eb6);
  padding: 50px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4),
              inset 0 0 100px rgba(255, 255, 255, 0.1);
  max-width: 400px;
  width: 90%;
  text-align: center;
  color: #f8f5f1;
}
/* Style the scrollbar */
.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
  .modal-content {
    padding: 30px;
    max-width: 90%;
  }
  
  input, button {
    font-size: 1em;
  }
  
  .mascot-login {
    max-width: 100px;
  }
  
  .additional-links {
    flex-direction: column;
    gap: 15px;
  }
}
