.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background: #f5e6ff; /* Light pastel purple */
  padding: 40px;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  position: relative;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

h2 {
  color: #3b3086; /* Deep twilight blue */
  margin-bottom: 20px;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 2px solid #ccc;
  font-size: 1em;
}

.next-button, .back-button, .submit-button {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  background-color: #fbd25d; /* Warm yellow for buttons */
  color: #1a1e5d; /* Deep twilight blue for text */
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.next-button:hover, .back-button:hover, .submit-button:hover {
  background-color: #e2c048;
}

