.terms-page {
  text-align: center;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #f8f5f1;
  background: linear-gradient(180deg, #1a1e5d, #3b3086, #482e82);
  box-sizing: border-box;
}

.terms-box {
  background-color: rgba(33, 0, 77, 0.85); /* Deep purple with transparency */
  padding: 40px;
  border-radius: 15px;
  width: 80%;
  max-width: 800px;
  margin-top: 50px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
  color: #f8f5f1;
  text-align: left;
  font-family: 'WonderYarnFont', sans-serif;
}

.terms-box h1 {
  font-size: 2.5em;
  color: #e2d4ff; /* Lavender color for the heading */
  margin-bottom: 20px;
}

.terms-box p, .terms-box ul {
  font-size: 1.2em;
  line-height: 1.8;
  color: #f8f5f1; /* Light text for readability */
}

.terms-box ul {
  padding-left: 20px; /* Indent for list */
}

.terms-box li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .terms-box {
    width: 95%;
    padding: 20px;
  }

  .terms-box h1 {
    font-size: 2em;
  }
}

