.child-form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
  text-align: start;
}

input { 
	box-sizing: border-box;
}

label {
  font-size: 1.2em;
  color: #3b3086;
  margin-bottom: 5px;
  display: block;
}

input, select {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.interest-select {
  width: 100%;
}

.submit-button {
  padding: 15px;
  background-color: #fbd25d;
  color: #3b3086;
  font-size: 1.2em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #e2c048;
}

/* Adjust the label and toggle switch to be on the same line */
.toggle-group {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Push label and toggle apart */
  margin-bottom: 15px;
}

.toggle-group label {
  margin-bottom: 0;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #3b3086;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
.gender-selection {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.gender-option {
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border: 2px solid transparent;
}

.gender-option img {
  width: 60px; /* Adjust to a prominent size */
  height: 60px;
}

.gender-option.selected {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.gender-option.selected[data-gender="boy"] {
  border-color: #4D9DE0; /* Light blue for boy */
}

.gender-option.selected[data-gender="girl"] {
  border-color: #FF8BD2; /* Light pink for girl */
}

.gender-option.selected[data-gender="neutral"] {
  border-color: #FFD65B; /* Yellow for neutral */
}



@media (max-width: 600px) {
  /* Gender Icon Size Adjustment */
  .gender-option img {
    width: 45px; /* Smaller size for icons */
    height: 45px;
  }

  /* Padding for gender options */
  .gender-option {
    padding: 5px;
  }

  /* Slider alignment fix */
  .slider:before {
    left: -2px; /* Adjust slider button position */
  }

  /* Form width adjustment */
  .child-form {
    width: 100%; /* Ensures form fits screen width */
    max-width: 390px; /* Matches iPhone 12/13 width */
    padding: 7px; /* Reduced padding for mobile */
    margin-top: 40px; /* Adds space at the top for the form */
  }

  /* Interest dropdown width adjustment */
  .interests-dropdown {
    width: 100%; /* Ensure dropdown fits within form */
  }

  /* Submit button scaling */
  .submit-button {
    width: 100%; /* Button spans full width on mobile */
    font-size: 1.1em; /* Slightly smaller font for mobile */
  }
input {
  width: 100%; /* Make sure input takes the full width of the parent */
  padding: 10px; /* Adjust padding as needed */
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box; /* Ensure padding and borders are included in the width calculation */
}
 .gender-option img {
    width: 45px;
    height: 45px;
  }

  .gender-option {
    padding: 5px;
  }

}

