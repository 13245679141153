.register-page {
  text-align: center;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #1a1e5d, #3b3086, #482e82);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  color: #f8f5f1;
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url('../assets/stars.png') repeat;
  background-size: cover;
  animation: twinkle 15s infinite linear;
  z-index: -1;
}

/*.content {
  max-width: 400px;
  background: rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  text-align: left;
  margin-top: 20px;
}*/

input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  border: none;
  font-size: 1em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button {
  width: 100%;
  padding: 12px;
  margin-top: 15px;
  border-radius: 8px;
  border: none;
  background-color: #673ab7;
  color: #fff;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #5e35b1;
}

.google-button {
  background-color: #db4437;
}

.error-message {
  color: #ff6f61;
  margin-top: 10px;
}

/*.mascot-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.mascot {
  max-width: 150px;
  animation: float 3s ease-in-out infinite;
}
*/
@media (max-width: 768px) {
  .content {
    max-width: 90%;
  }
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin: 15px 0;
  text-align: left;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px; /* Adds space between checkbox and label */
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.checkbox-group label {
  font-size: 1em;
  color: #f8f5f1;
  cursor: pointer;
  line-height: 1.4; /* Aligns label with checkbox */
  display: flex;
  align-items: center;
}

.checkbox-group a {
  color: #f8f5f1;
  text-decoration: underline;
  margin-left: 5px;
}

.checkbox-group a:hover {
  color: #b388ff;
}

a {
  color: #c7a4ff;
  text-decoration: underline;
}

a:hover {
  color: #b388ff;
}

