.about-us-page {
  text-align: center;
  padding: 80px 20px; /* Reduced padding for better mobile compatibility */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  overflow: auto; /* Allow scrolling */
  color: #f8f5f1;
  background: linear-gradient(180deg, #1a1e5d, #3b3086, #482e82); /* Same magical gradient */
  min-height: 100vh; /* Ensure it takes the full height of the viewport */
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.content {
  max-width: 600px;
  text-align: left;
  background: rgba(0, 0, 0, 0.15);
  padding: 30px;
  border-radius: 15px;
  animation: glow 5s infinite ease-in-out;
  box-sizing: border-box;
}

/*.mascot-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mascot {
  margin-top: 20px;
  max-width: 250px;
  animation: float 3s ease-in-out infinite;
}
*/
@keyframes glow {
  0% {
    background: rgba(0, 0, 0, 0.2);
  }
  50% {
    background: rgba(0, 0, 0, 0.3);
  }
  100% {
    background: rgba(0, 0, 0, 0.2);
  }
}

h1 {
  font-size: 2.5em;
  color: #e2d4ff; /* Lavender */
  font-family: 'Pacifico', cursive; /* Whimsical font */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Same style for mascot animations and layout */

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Adjust the stars height to always match the viewport */
  background: url('../assets/stars.png') repeat;
  background-size: cover;
  animation: twinkle 15s infinite linear;
  pointer-events: none;
  z-index: -1;
}
@media (max-width: 768px) {
  .content {
    padding: 20px; /* Reduced padding for smaller screens */
    text-align: center;
    margin: 0 auto;
    word-wrap: break-word;
  }

  .contact-us-page {
    padding: 60px 15px; /* Further reduced padding */
  }
  .content-with-aboutus {
    margin-top: 100px;
  }

  p {
    font-size: 1.1em;
    line-height: 1.5;
  }

  h1 {
    font-size: 2em;
    margin-bottom: 15px;
  }

  .mascot {
    max-width: 160px; /* Smaller mascot for mobile */
    margin-top: 15px;
  }

  .content-with-mascot {
    flex-direction: column;
    align-items: center;
    margin-top: 75px;
  }

  .content-with-aboutus {

    margin-top: 1000px;
    }
  .stars {
    height: auto;
    min-height: 100vh;
  }

  .content {
    max-width: 90%;
  }
}
/* For medium-sized screens (e.g., 1420x640) */
@media (max-width: 1440px) and (min-width: 1200px) {
  .content-with-aboutus {
    margin-top: 375px; /* Adds appropriate space at the top */
  }
}
