/* Main container */
.story-display-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  background: url('../assets/stars.png') repeat;
  background-size: cover;
  color: #f8f5f1;
  font-family: 'WonderYarnFont', sans-serif;
}

.story-box {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 15px;
  width: 90%;
  max-width: 800px;
  margin-bottom: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  transition: filter 0.3s ease;
}

.story-box h2 {
  font-size: 2.5em;
  color: #3b3086;
  margin-bottom: 20px;
  text-align: center;
}

.story-box p {
  font-size: 1.2em;
  line-height: 1.8;
  color: #3b3086;
  text-align: justify;
}
.story-box.blurred {
  filter: blur(5px);
  pointer-events: none;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.loading-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.5s ease-in-out;
}
.loading-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3b3086;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.loading-overlay p {
  color: #f8f5f1;
  font-size: 1.2em;
  margin-top: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Container for Most Popular and More Like This */
.recommendations-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
}

/* Section heading */
.card-section h2 {
  font-family: 'WonderYarnFont', cursive;
  font-size: 2em;
  color: #3b3086;
  text-align: center;
  margin-bottom: 20px;
}

/* Grid layout for Most Popular */
.recommendation-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 20px; /* Spacing between the cards */
  justify-items: center;
  align-items: center;
}

.story-card {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 15px;
  width: 150px; /* Adjusted for a tighter grid */
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: 'WonderYarnFont', sans-serif;
  color: #482e82 !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.story-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.story-card span {
  font-size: 1.5em;
  margin-top: 10px;
}

/* Animate emoji bounce */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.star-emoji {
  font-size: 2rem;
  display: inline-block;
  margin-top: 10px;
  animation: spin 200s linear infinite; /* Change "spin" to any animation you want */
}

.heart-emoji {
  font-size: 2rem;
  display: inline-block;
  margin-top: 10px;
  animation: bounce 200s linear infinite; /* Change "spin" to any animation you want */
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.recommendation-cards .story-card p {
  font-size: 1em;
  margin: 0;
}

/* Adjust alignment for section headers */
.card-section h2 {
  font-family: 'WonderYarnFont', cursive;
  font-size: 1.8em;
  color: #3b3086;
  text-align: center;
  margin-bottom: 20px;
}

/* Center the grid */
.recommendations-section {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  gap: 50px;
}

/* Adapting the layout for smaller screens */
@media (max-width: 768px) {
  .recommendations-section {
    flex-direction: column;
    align-items: center;
  }

  .card-section {
    margin: 20px 0;
  }

  .story-card {
    width: 100%;
  }
  .recommendation-cards {
    grid-template-columns: 1fr; /* Single column for smaller screens */
  }

  .story-card {
    width: 100%;
  }
  .image-story-wrap {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center;    /* Center align both image and text */
  }

  .storybook-image {
    width: 100%;  /* Make the image responsive */
    max-width: 300px; /* Limit max width for better appearance */
    margin-bottom: 20px; /* Add spacing below the image */
  }

  .story-content {
    text-align: left; /* Align the text to the left */
    width: 100%; /* Make sure the text takes full width */
  }

  .story-box {
    padding: 20px; /* Adjust padding to make the story box more compact */
  }

  .story-box h2 {
    font-size: 2em; /* Slightly reduce the title size on mobile */
  }
    .cover-image, .story-image {
    max-width: 100%;
    height: auto;
  }
}

/* Share section improvements */
.share-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share-button {
  background-color: #fbd25d;
  color: #1a1e5d;
  padding: 12px;
  border-radius: 10px;
  font-size: 1em;
  cursor: pointer;
  margin-bottom: 10px;
}

.share-button:hover {
  background-color: #e2c048;
}

.share-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3b3086;
  color: #fbd25d;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.1em;
}

.ad-section {
  margin-top: 30px;
  background-color: rgba(59, 48, 134, 0.8);
  color: #fbd25d;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  text-align: center;
}
.story-image {
  margin-bottom: 30px;
  text-align: center;
}

.image-story-wrap {
  display: flex; /* Wrap image and text side by side */
  align-items: flex-start; /* Align items to the top */
  gap: 20px; /* Add space between image and text */
}


.story-content p {
  font-size: 1.2em;
  line-height: 1.8;
  color: #3b3086;
  text-align: justify;
  margin: 10px 0;
}

.storybook-image {
  width: 250px; /* Resize image */
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Soft shadow */
}

.full-width-content p {
  font-size: 1.2em;
  line-height: 1.8;
  color: #3b3086;
  text-align: justify;
  margin: 10px 0;
}

.cover-image-container {
  text-align: center;
  margin-bottom: 30px;
}

.cover-image {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.story-image-container {
  text-align: center;
  margin: 20px 0;
}

.story-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.story-content {
  text-align: justify;
}

