.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background to indicate loading */
  z-index: 9999;
}

.loading-text {
  font-size: 1.5em;
  color: #f8f5f1;
  font-family: 'Pacifico', cursive;
  text-align: center;
  margin-top: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  border: 6px solid #f8f5f1;
  border-top: 6px solid #fbd25d; /* Match WonderYarn colors */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-top: 20px;
}

.mascot-spinner {
  width: 100px;
  animation: float 3s ease-in-out infinite; /* Mascot floating animation */
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

